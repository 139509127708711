import React, { useState } from 'react';
import Modal from '../components/Modal';
import './Services.css';

const ServicesPage = () => {


  return (
    <div className="services-section">

    </div>
  );
};

export default ServicesPage;
