import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import SlideShow from '../components/Slideshow';
import PictureFrame from '../components/PictureFrame';
import { div } from 'three/examples/jsm/nodes/Nodes.js';

const Home = () => {
    return (
        <div>
            <SlideShow />
            <div className="home-container">
                <div className="about-section">
                    <h2 className='noto-serif-jp-weight900'>About Us</h2>
                    <p className='noto-serif-jp-weight200'>
                        トータルインテリアエコは、1994年に設立されたインテリアデザイン専門の会社です。
                        私たちは、「空間を通じて人々の生活を豊かにする」というビジョンのもと、
                        住宅からオフィス、商業施設まで、さまざまな空間のデザインを手掛けています。
                        デザイン一つひとつに心を込めて、お客様一人ひとりのニーズに応じた最適な空間作りを目指しています。
                    </p>
                </div>

                <div className="portfolio-section">
                    <h2 className='noto-serif-jp-weight900'>Works</h2>
                    <div className="gallery">
                        <PictureFrame image={`${process.env.PUBLIC_URL}/images/2.webp`} title="パーソナルジム" link="https://trigger2023.com" orientation="landscape" />
                        <PictureFrame image={`${process.env.PUBLIC_URL}/images/6.webp`} title="お菓子屋さん" link="https://reolch.github.io/portfolio_lp_hand_in_hand/" orientation="landscape" />
                        <PictureFrame image={`${process.env.PUBLIC_URL}/images/10.webp`} title="プログラミングスクール" orientation="landscape" />
                    </div>

                </div>

                <div className="contact-section">
                    <h2 className='noto-serif-jp-weight900'>Contact Us</h2>
                    <p className='noto-serif-jp-weight200'>
                        お問い合わせは、以下のフォームから送信してください。
                    </p>
                    <Link to="/contact" className="contact-button">Contact Us</Link>
                </div>
            </div>
        </div>


    );
};

export default Home;